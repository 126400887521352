<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <KTCodePreview v-bind:title="'Phiếu trả hàng'">
          <template v-slot:preview>
            <b-form ref="form" lazy-validation class="row">
              <b-container class="bv-example-row">
                <b-row>
                  <b-col class="col-md-4">
                    <b-form-group id="input-store">
                      <label for="input-name">Cửa hàng:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptions"
                        @selected="onSelected"
                        :limit="10"
                        @input="onInputChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Tên cửa hàng',
                        }"
                        :should-render-suggestions="
                          (size, loading) => size >= 0 && !loading
                        "
                        v-model="searchStore"
                      >
                        <template slot-scope="{ suggestion }">{{
                          suggestion.item.name
                        }}</template>
                      </vue-autosuggest>
                    </b-form-group>

                    <b-form-group id="input-group-name">
                      <label for="input-name">Loại phiếu:</label>
                      <b-form-select
                        id="input-1"
                        class="select-style"
                        v-model="selectType"
                        :options="listType"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn loại</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-name">
                      <label for="input-name">Kiểu chiết khấu:</label>
                      <b-form-select
                        id="input-1"
                        class="select-style"
                        v-model="selectDiscountType"
                        :options="listDiscountType"
                        size="sm"
                        value-field="id"
                        text-field="name"
                        disabled-field="notEnabled"
                      >
                        <template v-slot:first>
                          <b-form-select-option :value="null" disabled
                            >Chọn kiểu chiết khấu</b-form-select-option
                          >
                        </template>
                      </b-form-select>
                    </b-form-group>

                    <b-form-group id="input-group-discount">
                      <label for="input-name">Chiết khấu:</label>
                      <b-form-input
                        id="input-discount"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="discount"
                        placeholder="Chiết khấu"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-sale">
                      <label for="input-name">Nhân viên bán hàng:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsEmployee"
                        @selected="onSelectedSale"
                        :limit="100"
                        @input="onInputChangeSale"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhân viên bán hàng',
                        }"
                        v-model="saleName"
                      >
                        <template slot-scope="{ suggestion }">{{
                          suggestion.item.fullName
                        }}</template>
                      </vue-autosuggest>
                    </b-form-group>

                    <b-form-group id="input-group-note">
                      <label for="input-note">Ghi chú CSKH:</label>
                      <b-form-textarea
                        size="sm"
                        id="textarea1"
                        class="input-style"
                        v-model="note"
                        :placeholder="'Thêm ghi chú'"
                        :rows="6"
                        :max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-md-4">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Họ và tên:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="customerName"
                        required
                        placeholder="Họ tên khách hàng"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group id="input-group-phone">
                      <label for="input-phone">Điện thoại:</label>
                      <vue-autosuggest
                        :suggestions="filteredOptionsCustomer"
                        @selected="onSelectedCustomer"
                        :limit="100"
                        @input="onInputChangeCustomer"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'SĐT Khách hàng',
                        }"
                        v-model="searchCustomer"
                      >
                        <template slot-scope="{ suggestion }">{{
                          suggestion.item.phoneNo
                        }}</template>
                      </vue-autosuggest>
                    </b-form-group>

                    <b-form-group id="input-group-address">
                      <label for="input-address">Địa chỉ:</label>
                      <b-form-input
                        id="input-address"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="address"
                        required
                        placeholder="Địa chỉ khách hàng"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-email">
                      <label for="input-email">Email:</label>
                      <b-form-input
                        id="input-email"
                        class="input-style"
                        size="sm"
                        type="text"
                        v-model="email"
                        required
                        placeholder="Nhập email"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col class="col-md-4">
                    <b-form-group id="input-group-1">
                      <label for="input-name">Tiền mặt trả khách:</label>
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="cash2Pay"
                        placeholder="Nhập tiền mặt trả khách"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-1">
                      <label for="input-cash-account"
                        >Tài khoản trả tiền:</label
                      >
                      <vue-autosuggest
                        v-model="payAccount"
                        :suggestions="filteredCashAccOptions"
                        @selected="onCashAccSelected"
                        :limit="100"
                        @input="onInputCashAccChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tài khoản chuyển khoản',
                        }"
                      >
                        <template slot-scope="{ suggestion }">{{
                          suggestion.item.name
                        }}</template>
                      </vue-autosuggest>
                    </b-form-group>

                    <b-form-group id="input-group-1">
                      <label for="input-name"
                        >Tiền chuyển khoản trả khách:</label
                      >
                      <b-form-input
                        id="input-2"
                        class="input-style text-right"
                        size="sm"
                        type="text"
                        v-model="cashTransfer2Pay"
                        placeholder="Nhập tiền chuyển khoản trả khách"
                        v-mask="mask"
                      ></b-form-input>
                    </b-form-group>

                    <b-form-group id="input-group-transfer-account">
                      <label for="input-transfer-account"
                        >Tài khoản chuyển khoản:</label
                      >
                      <vue-autosuggest
                        v-model="transferAccount"
                        :suggestions="filteredAccOptions"
                        @selected="onAccSelected"
                        :limit="100"
                        @input="onInputAccChange"
                        :input-props="{
                          id: 'autosuggest__input',
                          placeholder: 'Nhập tài khoản chuyển khoản',
                        }"
                      >
                        <template slot-scope="{ suggestion }">{{
                          suggestion.item.name
                        }}</template>
                      </vue-autosuggest>
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-row class="mb-3">
                  <b-col>
                    <vue-autosuggest
                      v-model="searchProduct"
                      :suggestions="filteredOptionsProduct"
                      @selected="onSelectedProduct"
                      :limit="100"
                      @input="onInputChangeProduct"
                      :input-props="{
                        id: 'autosuggest__input',
                        placeholder: 'Tìm kiếm sản phẩm',
                        style: 'border-radius:0px!important',
                      }"
                    >
                      <template slot-scope="{ suggestion }">
                        <div>
                          ({{ convertPrice(suggestion.item.sellingPrice) }}) (
                          <span
                            v-if="suggestion.item.quantityInStock > 0"
                            class="text-success"
                          >
                            Tồn TCB:
                            {{ suggestion.item.quantityInStock }}
                          </span>
                          <span v-else class="text-danger">
                            Tồn TCB:
                            {{ suggestion.item.quantityInStock }}
                          </span>
                          )
                          {{ suggestion.item.productName }}
                        </div>
                      </template>
                    </vue-autosuggest>
                  </b-col>
                </b-row>

                <b-row>
                  <b-col>
                    <table
                      class="table table-bordered table-vertical-center table-hover"
                    >
                      <thead>
                        <tr>
                          <th class="headerTable" style="textalign: center">
                            STT
                          </th>
                          <th class="name headerTable">Sản phẩm</th>
                          <th class="value headerTable">Số lượng</th>
                          <th class="code headerTable">Giá</th>
                          <th class="code headerTable">Thành tiền</th>
                          <th class="code headerTable">Chiết khấu</th>
                          <th v-if="true"></th>
                        </tr>
                      </thead>

                      <tbody v-for="(item, index) in listBillItem" :key="index">
                        <BillItem
                          v-bind:productItem="item"
                          v-bind:index="index"
                          v-bind:editable="true"
                          v-on:deleteItem="deleteItemInArray"
                          v-on:update="updateItem"
                          v-on:checkValidImei="checkValidImei"
                        />
                      </tbody>
                      <tfoot>
                        <tr>
                          <th style="text-align: center" id="total" colspan="4">
                            Tổng phải trả:
                          </th>
                          <td style="text-align: right; padding: 0px 20px">
                            {{ convertPrice(totalPrice) }}
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
          </template>

          <template v-slot:foot>
            <b-container class="bv-example-row">
              <b-row>
                <b-col>
                  <b-button
                    tabindex="15"
                    style="fontweight: 600; width: 70px"
                    variant="primary"
                    size="sm"
                    type="submit"
                    @click="createBill"
                    >Lưu</b-button
                  >
                  <router-link to="/orders" tag="button">
                    <b-button
                      tabindex="16"
                      style="margin-left: 10px; font-weight: 600; width: 70px"
                      variant="secondary"
                      size="sm"
                      >Hủy</b-button
                    >
                  </router-link>
                </b-col>
              </b-row>
            </b-container>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>
<style scoped>
.form-group label {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import ApiService from '@/core/services/api.service';
import { currencyMask, unMaskPrice } from './../../../utils/common';
// import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { removeAccents } from '../../../utils/common';
// import Swal from 'sweetalert2';
import BillItem from '../../components/BillItem.vue';
import { TIME_TRIGGER } from './../../../utils/constants';
import decounce from 'debounce';
import { xoa_dau } from './../../../utils/common';

export default {
  mixins: [validationMixin],
  data() {
    return {
      selectedSaleId: '',
      selectedCustomerId: '',
      selectedAccId: '',
      selectedCashId: '',
      cashAccOptions: [
        {
          data: [],
        },
      ],
      filteredCashAccOptions: [],
      accountantOptions: [
        {
          data: [],
        },
      ],
      filteredAccOptions: [],
      filteredOptions: [],
      options: [
        {
          data: [],
        },
      ],
      selectedStore: '',
      searchStore: '',
      selectType: 1,
      listType: [
        {
          id: 1,
          name: 'Bán lẻ',
        },
        {
          id: 2,
          name: 'Bán buôn',
        },
      ],
      discount: '',
      saleName: '',
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      selectDiscountType: 2,
      listDiscountType: [
        {
          id: 1,
          name: '%',
        },
        {
          id: 2,
          name: 'Tiền mặt',
        },
      ],
      note: '',
      filteredOptionsCustomer: [],
      searchCustomer: '',
      optionCustomer: [
        {
          data: [],
        },
      ],
      customerName: '',
      address: '',
      email: '',
      mask: currencyMask,
      cash2Pay: 0,
      payAccount: '',
      cashTransfer2Pay: 0,
      transferAccount: '',
      searchProduct: '',
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      listBillItem: [],
      listProduct: [],
      listStore: [],
      countProduct: 0,
      proSTT: 0,
    };
  },
  methods: {
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].proId = item.proId;
          this.listBillItem[index].price = parseInt(item.price);
          this.listBillItem[index].quantity = parseInt(item.quantity);
          this.listBillItem[index].totalPrice = item.totalPrice;
          this.listBillItem[index].discount = item.discount;
        }
      });
    },
    deleteItemInArray(id) {
      for (var i = 0; i < this.listBillItem.length; i++) {
        if (this.listBillItem[i].id === id) {
          this.listBillItem.splice(i, 1);
        }
      }
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    fetchCashAcc: async function () {
      this.listCashAccSearch = [];
      await ApiService.setHeader();
      if (this.selectedStore !== '') {
        let params = {
          storeId: this.selectedStore,
          //  tk tien mat
          type: 1,
        };
        await ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
            this.transferAccount = this.cashAccOptions[0].data[0].name;
            this.selectedAccId = this.cashAccOptions[0].data[0].id;
          }
        );
      } else {
        let params = {
          //  tk tien mat
          type: 1,
        };
        await ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.cashAccOptions[0].data = [];
            response.data.data.forEach((item) => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            });
            this.filteredCashAccOptions = [
              { data: this.cashAccOptions[0].data },
            ];
            this.transferAccount = this.cashAccOptions[0].data[0].name;
            this.selectedAccId = this.cashAccOptions[0].data[0].id;
          }
        );
      }
    },
    onInputCashAccChange(text) {
      this.payAccount = text;

      const filteredData = this.cashAccOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredCashAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onCashAccSelected(option) {
      this.payAccount = option.item.name;
      this.selectedCashId = option.item.id;
    },
    onInputAccChange(text) {
      this.transferAccount = text;

      const filteredData = this.accountantOptions[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredAccOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onAccSelected(option) {
      this.transferAccount = option.item.name;
      this.selectedAccId = option.item.id;
    },
    fetchAccountant: async function () {
      ApiService.setHeader();
      if (this.selectedStore !== '') {
        let params = {
          storeId: this.selectedStore,
          //  tk ngan hang
          type: 2,
        };
        await ApiService.query('accountants/getByStore', { params }).then(
          (response) => {
            this.accountantOptions[0].data = [];
            response.data.data.forEach((item) => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.accountantOptions[0].data.push(acc);
            });
            this.filteredAccOptions = [
              { data: this.accountantOptions[0].data },
            ];
            this.payAccount = this.accountantOptions[0].data[0].name;
            this.selectedCashId = this.accountantOptions[0].data[0].id;
          }
        );
      } else {
        let params = {
          //  tk ngan hang
          type: 2,
        };
        await ApiService.query('accountants/getByType', { params }).then(
          (response) => {
            this.accountantOptions[0].data = [];
            response.data.data.forEach((item) => {
              let acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.accountantOptions[0].data.push(acc);
            });
            this.filteredAccOptions = [
              { data: this.accountantOptions[0].data },
            ];
            this.payAccount = this.accountantOptions[0].data[0].name;
            this.selectedCashId = this.accountantOptions[0].data[0].id;
          }
        );
      }
    },
    fetchStore: async function () {
      this.options[0].data = [];
      await ApiService.setHeader();
      await ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.options[0].data.push(element);
        });
      });
    },
    onInputChange(text) {
      this.searchStore = text;
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    onSelected(option) {
      this.searchStore = option.item.name;
      this.selectedStore = option.item.id;
      //this.fetchProduct();
    },
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    fetchProduct() {
      this.listProduct = [];
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?storeId=${this.selectedStore}&searchProduct=${this.searchProduct}`
      ).then(({ data }) => {
        const products = data.data;
        this.listProduct = products;
        products.map((element) => {
          let suggestName = `(${this.convertPrice(
            element.sellingPrice
          )}) (Tồn CTB: ${element.quantityInStock}) ${element.productCode} - ${
            element.productName
          }`;
          let item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            quantityInStock: element.quantityInStock,
            sellingPrice: element.sellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
          };
          this.optionsProduct[0].data.push(item);
        });
      });
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;

      this.debounceInputProduct();
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    isExistImeiCode(listProduct, imeiCode) {
      let check = false;
      for (let index = 0; index < listProduct.length; index++) {
        const element = listProduct[index];
        if (element.imeiCode === imeiCode) {
          check = true;
          break;
        }
      }
      return check;
    },
    onSelectedProduct(option) {
      this.searchProduct = '';
      let selectedProduct = option.item;
      let imeiCode = '';
      let productType = selectedProduct.productType;
      if (this.selectTypeSearch === 2) {
        imeiCode = selectedProduct.imeiCode;
        productType = 2;
      }
      let billItem = {
        id: 0,
        productId: selectedProduct.productId,
        name: selectedProduct.productName,
        code: selectedProduct.productCode,
        price: selectedProduct.sellingPrice,
        type: productType,
        quantity: 1,
        imeiCode: imeiCode,
        discount: 0,
        quantityInStock: selectedProduct.quantityInStock,
        storeId: this.selectedClassStore,
        comboItems: selectedProduct.listProductInCombo,
        billItemType: 1,
        checked: true,
        listAccessoryPromotion: selectedProduct.listAccessoryPromotion,
        returnPrice: 0,
        accessoryGroupId: '',
        accessoryGroupCode: '',
        stt: 0,
        note: '',
      };
      if (this.selectTypeSearch === 2) {
        if (this.isExistImeiCode(this.listBillItem, imeiCode)) {
          this.makeToastFaile('Mã IMEI đã tồn tại trong hóa đơn');
        } else {
          this.countProduct++;
          this.proSTT++;
          billItem.stt = this.proSTT;
          billItem.id = this.countProduct;
          this.listBillItem.push(billItem);
        }
      } else {
        // if (selectedProduct.quantityInStock > 0) {
        this.countProduct++;
        this.proSTT++;
        billItem.stt = this.proSTT;
        billItem.id = this.countProduct;
        this.listBillItem.push(billItem);
        // } else {
        //   this.makeToastFaile('Số sản phẩm tồn trong kho không đủ!');
        // }
      }
    },
    getListCustomer() {
      ApiService.setHeader();
      ApiService.get('customer/getAll').then((data) => {
        const customers = data.data.data;
        customers.map((element) => {
          this.optionCustomer[0].data.push(element);
        });
      });
    },
    onSelectedCustomer(option) {
      this.searchCustomer = option.item.phoneNo;
      this.selectedCustomerId = option.item.id;
      this.getCustomerInfoById();
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.searchCustomer = text;
      const filtedData = this.optionCustomer[0].data
        .filter((item) => {
          return (
            removeAccents(item.phoneNo)
              .toLowerCase()
              .indexOf(removeAccents(text).toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsCustomer = [
        {
          data: filtedData,
        },
      ];
    },
    getCustomerInfoById() {
      ApiService.setHeader();
      ApiService.get(`customer/${this.selectedCustomerId}`).then((data) => {
        let customerInfo = data.data.data;
        this.customerName = customerInfo.fullName;
        this.address = customerInfo.address;
        this.email = customerInfo.email;
      });
    },
    createBill: async function () {
      let listPro = [];
      this.listBillItem.forEach((element) => {
        let item = {
          productId: element.id,
          productPrice: unMaskPrice(element.price),
          quantity: element.quantity,
          discountAmount: unMaskPrice(element.discount),
          imeiCode: element.imeiCode,
        };
        listPro.push(item);
      });
      let data = {
        storeId: this.selectedStore,
        type: this.selectType,
        discount: this.discount,
        discountType: this.selectDiscountType,
        saleId: this.selectedSaleId,
        note: this.note,
        customerName: this.customerName,
        customerPhone: this.searchCustomer,
        address: this.address,
        email: this.email,
        cash2Pay: unMaskPrice(this.cash2Pay),
        selectedCashId: this.selectedCashId,
        cashTransfer2Pay: unMaskPrice(this.cashTransfer2Pay),
        selectedAccId: this.selectedAccId,
        listBillItem: listPro,
      };
      await ApiService.setHeader();
      ApiService.post('bills/returnGood', data).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          setTimeout(() => {
            this.$router.push({
              path: '/returnBills',
            });
          }, 1000);
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    onSelectedSale(option) {
      this.saleName = option.item.fullName;
      this.selectedSaleId = option.item.id;
    },
    onInputChangeSale(text) {
      if (!text) {
        text = '';
      }
      this.saleName = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          let nameNoneSign = xoa_dau(item);
          return (
            item.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    getListEmployee() {
      ApiService.setHeader();
      ApiService.get('employees/getAll').then((data) => {
        const employees = data.data.data;
        employees.map((element) => {
          this.optionsEmployee[0].data.push(element);
        });
      });
    },
    debounceInputProduct: decounce(function () {
      this.searchProductAPI();
    }, TIME_TRIGGER),
    searchProductAPI() {
      if (this.selectTypeSearch === 1) {
        this.fetchProduct();
      } else if (this.selectTypeSearch === 2) {
        this.fetchProductImei();
      }
    },
    fetchProductImei() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.selectedClassStore}&imeiCode=${this.searchProduct}`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          let suggestName = `(${this.convertPrice(element.sellingPrice)}) ${
            element.productCode
          } - ${element.productName} - IMEI: ${element.imeiCode}`;
          let item = {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            suggestName: suggestName,
            productType: element.productType,
            imeiCode: element.imeiCode,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            quantityInStock: element.quantityInStock,
          };
          this.optionsProduct[0].data.push(item);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
  },
  components: {
    KTCodePreview,
    BillItem,
  },
  computed: {
    totalPrice() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        total +=
          unMaskPrice(element.price) * element.quantity -
          unMaskPrice(element.discount);
      }
      let discountAmount = 0;
      if (this.discount !== '') {
        discountAmount = unMaskPrice(this.discount);
      }
      if (this.selectDiscountType === 1) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      if (total < 0) {
        total = 0;
      }
      return total;
    },
  },
  created() {
    this.getListEmployee();
    this.getListCustomer();
    //this.fetchProduct();
    this.fetchStore();
    this.fetchAccountant();
    this.fetchCashAcc();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu trả hàng', route: '/returnBills' },
      { title: 'Danh sách phiếu trả hàng', route: '/returnBills' },
      { title: 'Thêm mới phiếu trả hàng' },
    ]);
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 400;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
